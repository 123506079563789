<template>
  <div class="py-20 bg-white">
    <a-collapse
      :activeKey="activeKey"
      :bordered="false"
      style="background-color: transparent"
    >
      <a-collapse-panel
        style="border: none"
        key="1"
        :showArrow="false"
        :disabled="true"
      >
        <Header slot="header">数字化情况</Header>
        <Extra
          @click.native="addProduct"
          v-if="!products.length"
          slot="extra"
        />
        <ExtraUpdate
          @click.native="updateProduct(products[0])"
          v-if="products.length && products[0].mode === 'display'"
          slot="extra"
        />
        <a-row>
          <a-col :span="24" v-for="item in products" :key="item.ID">
            <!-- display 和 update -->
            <Digitalize :mode="item.mode" :dataSource="item">
              <a-space slot="update" slot-scope="{ validate }">
                <a-button @click="cancelProduct(item)" type="primary" ghost
                  >取消</a-button
                >
                <a-button @click="saveProduct(validate)" type="primary"
                  >保存</a-button
                >
              </a-space>
            </Digitalize>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Extra from "./components/extra.vue";
import ExtraUpdate from "./components/extraUpdate.vue";
import Digitalize from "./components/Digitalize.vue";
import { updateDigital, getCompanyRecoginze } from "@/api";
export default {
  components: { Header, Extra, Digitalize, ExtraUpdate },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    activeKey() {
      let active = [];
      if (this.products.length) active.push("1");
      return active;
    },
    companyId() {
      return this.$store.state.user.USER_INFO.company_id;
    },
  },
  created() {
    this.getCompanyRecoginze();
  },
  methods: {
    // 获取数字化情况
    getCompanyRecoginze() {
      getCompanyRecoginze().then(({ data }) => {
        if (data.Digital)
          this.products = [{ ...data.Digital, ID: 1, mode: "display" }];
      });
    },
    // 更新数字化情况
    updateDigital(data) {
      updateDigital(this.companyId, data).then(() => {
        this.$message.success("更新成功");
        this.getCompanyRecoginze();
      });
    },
    // 添加数字化情况
    addProduct() {
      this.products.push({ mode: "update" });
    },
    // 删除数字化情况
    delProduct(i) {
      this.products.splice(i, 1);
    },
    // 修改数字化情况
    updateProduct(data) {
      this.$set(data, "mode", "update");
    },
    // 保存数字化情况
    saveProduct(validate) {
      validate().then((res) => {
        this.updateDigital(res);
      });
    },
    // 取消保存数字化情况
    cancelProduct(data, i) {
      if (data.ID) {
        this.updateState(data, "display");
      } else {
        this.delProduct(i);
      }
    },
    // 修改状态
    updateState(data, state) {
      this.$set(data, "mode", state);
    },
  },
};
</script>