<template>
  <div class="hover:bg-f9 px-20 py-10" :class="{ 'bg-f9': mode === 'update' }">
    <!-- display -->
    <div v-if="mode === 'display'" class="text-sm">
      <div class="mb-20">
        <div class="text-999 mb-10">您的企业数字化处于哪个阶段？</div>
        <div>{{ model.PhaseName }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">您的企业在哪些方面已经实现了数字化？</div>
        <div>{{ model.AspectsName }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">
          希望通过数字化转型实现什么样的战略目标？
        </div>
        <div>{{ model.Target }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">数字化转型面临的困难和阻碍是什么？</div>
        <div>{{ model.TroublesName }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">请您详细描述困难和阻碍的内容</div>
        <div>{{ model.Problem }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">近期亟待解决的需求</div>
        <div>{{ model.Demand }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">
          如果有一个问题需要协会支持您解决，这个问题会是什么？
        </div>
        <div>{{ model.Question }}</div>
      </div>
    </div>
    <!-- update -->
    <div v-else-if="mode === 'update'">
      <a-form-model
        ref="container"
        :model="model"
        labelAlign="left"
        :colon="false"
      >
        <gf-form-item prop="Phase" label="你的企业数字化处于哪个阶段?">
          <gf-custom-select
            placeholder="请选择"
            flag="DigitalPhase"
            :custom="true"
            v-model="model.Phase"
          ></gf-custom-select>
        </gf-form-item>
        <gf-form-item
          prop="Aspects"
          label="您的企业在哪些方面已经实现了数字化?"
        >
          <gf-custom-select
            placeholder="请选择"
            flag="DigitalAspect"
            mode="multiple"
            :custom="true"
            v-model="model.Aspects"
          ></gf-custom-select>
        </gf-form-item>
        <gf-form-item
          prop="Target"
          label="希望通过数字化转型实现什么样的战略目标?"
        >
          <gf-re-textarea
            placeholder="请详细描述想实现什么样的战略目标"
            :rows="5"
            :max="300"
            v-model="model.Target"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item prop="Troubles" label="数字化转型面临的困难和阻碍是什么?">
          <gf-custom-select
            placeholder="请选择"
            flag="DigitalTrouble"
            mode="multiple"
            :custom="true"
            v-model="model.Troubles"
          ></gf-custom-select>
        </gf-form-item>
        <gf-form-item prop="Problem" label="请您详细描述困难和阻碍的内容">
          <gf-re-textarea
            placeholder="请在此详细描述您的困难和阻碍"
            :rows="5"
            :max="300"
            v-model="model.Problem"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item prop="Demand" label="近期亟待解决的需求">
          <gf-re-textarea
            placeholder="请在此详细描述您亟待解决的需求，没有则无需填写"
            :rows="5"
            :max="300"
            v-model="model.Demand"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item
          prop="Question"
          label="如果有一个问题需要协会支持您解决，这个问题会是什么"
        >
          <gf-re-textarea
            placeholder="请在此详细描述，没有则无需填写"
            :rows="5"
            :max="300"
            v-model="model.Question"
          ></gf-re-textarea>
        </gf-form-item>
      </a-form-model>
      <div class="text-right">
        <slot name="update" v-bind="{ validate }"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: "display",
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            const temp = { ...this.model };
            temp.Aspects = temp.Aspects.map((item) => {
              if (typeof item === "string") {
                return {
                  Name: item,
                  ID: 0,
                };
              } else {
                return this.options.DigitalAspect.find((option) => {
                  return item === option.ID;
                });
              }
            });

            temp.Troubles = temp.Troubles.map((item) => {
              if (typeof item === "string") {
                return {
                  Name: item,
                  ID: 0,
                };
              } else {
                return this.options.DigitalTrouble.find((option) => {
                  return item === option.ID;
                });
              }
            });

            if (typeof temp.Phase === "string") {
              temp.Phase = {
                Name: temp.Phase,
                ID: 0,
              };
            } else {
              temp.Phase = this.options.DigitalPhase.find((option) => {
                return temp.Phase === option.ID;
              });
            }
            resolve(temp);
          } else {
            reject();
          }
        });
      });
    },
  },
  created() {
    const temp = { ...this.dataSource };
    if (temp.Aspects) {
      temp.AspectsName = temp.Aspects.map((item) => item.Name).join("、");
      temp.Aspects = temp.Aspects.map((item) => item.ID || item.Name);
    }
    if (temp.Phase) {
      temp.PhaseName = temp.Phase.Name;
      temp.Phase = temp.Phase.ID || temp.PhaseName;
    }
    if (temp.Troubles) {
      temp.TroublesName = temp.Troubles.map((item) => item.Name).join("、");
      temp.Troubles = temp.Troubles.map((item) => item.ID || item.Name);
    }
    this.model = temp;
  },
  watch: {
    dataSource(val) {
      if (val.Aspects) {
        val.AspectsName = val.Aspects.map((item) => item.Name).join("、");
        val.Aspects = val.Aspects.map((item) => item.ID || item.Name);
      }
      if (val.Phase) {
        val.PhaseName = val.Phase.Name;
        val.Phase = val.Phase.ID;
      }
      if (val.Troubles) {
        val.TroublesName = val.Troubles.map((item) => item.Name).join("、");
        val.Troubles = val.Troubles.map((item) => item.ID || item.Name);
      }
      this.model = val;
    },
  },
};
</script>